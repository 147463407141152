import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

export default function LoginForm() {
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInSuccessUrl: "/",
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        requireDisplayName: true,
        forceSameDevice: true
      },
      // {
      //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      //   // Invisible reCAPTCHA with image challenge and bottom left badge.
      //   recaptchaParameters: {
      //     type: 'image',
      //     size: 'invisible',
      //     badge: 'bottomleft'
      //   },
      //   defaultCountry: 'VN'
      // },
    ],
    tosUrl: 'https://hackerthon.net/terms',
    privacyPolicyUrl: "https://hackerthon.net/privacy"
  };

  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;
}