// @mui
import { Button, Divider, Stack, Typography } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function AuthFirebaseSocial() {
  const { loginWithGoogle, loginWithGithub, loginWithApple } = useAuth();

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={loginWithGoogle}>
          <Iconify icon={'eva:google-fill'} color="#DF3E30" width={24} height={24} />
        </Button>

        {/* <Button fullWidth size="large" color="inherit" variant="outlined" onClick={loginWithGithub}>
          <Iconify icon={'mdi:github'} color="#666" width={24} height={24} />
        </Button> */}

        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={loginWithApple}>
          <Iconify icon={'ic:sharp-apple'} color="#666" width={24} height={24} />
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
